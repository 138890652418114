import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  WhatsappShareButton,
  WhatsappIcon,
  PinterestShareButton,
  PinterestIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share'

import ArrowRight from './icons/ArrowRight'

import { SocialShareLayout } from '../styles/ItemDetailsLayout'
import { ParagraphM } from '../styles/Typography'

/**
 * A social share handler used in item details.
 * @param {ComponentProps} props
 * @param {String} props.slug
 * @returns {FunctionComponent}
 */
const SocialShare = ({ slug }) => {
  const [shareUrl, setShareUrl] = useState()

  useEffect(() => {
    setShareUrl(window.location.href)
  })

  const copyToClipboard = (value) => {
    if (!value) {
      return
    }

    navigator.clipboard.writeText(value).then(() => {
      document.getElementById('copy-link')?.classList.add('copied')

      setTimeout(() => {
        document.getElementById('copy-link')?.classList.remove('copied')
      }, 1000)
    })
  }

  return (
    <SocialShareLayout column alignItems="flex-start">
      <ParagraphM weight={600} className="group-title">
        Copy link
      </ParagraphM>
      <button
        className="copy-link"
        id="copy-link"
        onClick={() => copyToClipboard(`https://colorized.mk/${slug}`)}
      >
        <ArrowRight />
        <span>https://colorized.mk/{slug}</span>
      </button>
      <ParagraphM weight={600} className="group-title">
        Share now
      </ParagraphM>
      <div className="links">
        <WhatsappShareButton url={shareUrl}>
          <WhatsappIcon size={28} round />
        </WhatsappShareButton>
        <PinterestShareButton url={shareUrl}>
          <PinterestIcon size={28} round />
        </PinterestShareButton>
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon size={28} round />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon size={28} round />
        </TwitterShareButton>
        <FacebookMessengerShareButton url={shareUrl}>
          <FacebookMessengerIcon size={28} round />
        </FacebookMessengerShareButton>
        <TelegramShareButton url={shareUrl}>
          <TelegramIcon size={28} round />
        </TelegramShareButton>
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon size={28} round />
        </LinkedinShareButton>
      </div>
    </SocialShareLayout>
  )
}

SocialShare.propTypes = {
  slug: PropTypes.string.isRequired,
}

export default SocialShare
