import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate, graphql } from 'gatsby'
import PortableText from '@sanity/block-content-to-react'
import Img from 'gatsby-image'

import { Container } from '../styles/PageLayout'
import {
  ItemDetailsLayout,
  Description,
  Image,
} from '../styles/ItemDetailsLayout'
import { Title } from '../styles/Typography'

import SEO from './SEO'
import SocialShare from './SocialShare'

/**
 * Item details layout getting data
 * from Sanity and displaying in a modal.
 * @param {ComponentProps} props
 * @param {Object} props.data
 * @param {Object} props.pageContext
 * @returns {StatelessComponent}
 */
const ItemDetails = ({ data, pageContext }) => {
  const item = data.item

  const { currentItem } = pageContext
  const pageSize = 12
  const pageNumber = Math.ceil((currentItem + 1) / pageSize)

  const description = item.description.map((i) => {
    i.markDefs = []
    return i
  })

  const handleBack = () => {
    navigate(`/gallery/${pageNumber}`)
    document.getElementById('fb-root').classList.remove('hide')
  }

  const handlePrev = () => {
    navigate(`/${pageContext.previousSlug}`)
  }

  const handleNext = () => {
    navigate(`/${pageContext.nextSlug}`)
  }

  const detectKeydown = useCallback((event) => {
    if (event.keyCode === 27) {
      handleBack()
    }

    if (event.keyCode === 37) {
      handlePrev()
    }

    if (event.keyCode === 39) {
      handleNext()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', detectKeydown, false)

    return () => {
      document.removeEventListener('keydown', detectKeydown, false)
    }
  }, [])

  return (
    <>
      <SEO title={item.name} image={item.images[0]?.asset?.fluid?.src} />
      <ItemDetailsLayout>
        <Container column mobileColumn alignItems="flex-start">
          <div className="controls">
            <a className="control" onClick={handleBack}>
              Back to gallery
            </a>
            <div className="controls-group">
              <Link
                to={`/${pageContext.previousSlug}`}
                className={
                  pageContext.currentItem <= 0 ? 'control disabled' : 'control'
                }
              >
                Prev
              </Link>
              <Link
                to={`/${pageContext.nextSlug}`}
                className={
                  pageContext.currentItem >= pageContext.allItems - 1
                    ? 'control disabled'
                    : 'control'
                }
              >
                Next
              </Link>
            </div>
          </div>

          <Container
            mobileColumn
            columnReverse
            alignItems="flex-start"
            mobileAlignItems="center"
          >
            <div className="images">
              {item.images.map((image, index) => (
                <Image key={index}>
                  <Img fluid={image.asset.fluid} alt={item.name} />
                </Image>
              ))}
            </div>
            <div className="description">
              <Title weight={600} className="title">
                {item.name}
              </Title>
              <Description>
                <PortableText blocks={description} />
              </Description>
              <SocialShare slug={item.slug.current} />
            </div>
          </Container>
        </Container>
      </ItemDetailsLayout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    item: sanityItem(slug: { current: { eq: $slug } }) {
      id
      name
      description {
        _key
        _type
        children {
          _key
          _type
          marks
          text
        }
        style
      }
      slug {
        current
      }
      images {
        asset {
          fluid(maxWidth: 350) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

ItemDetails.propTypes = {
  data: PropTypes.shape({
    item: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.array,
      images: PropTypes.array,
      name: PropTypes.string,
      slug: PropTypes.shape({
        current: PropTypes.string,
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    currentItem: PropTypes.number,
    nextSlug: PropTypes.string,
    previousSlug: PropTypes.string,
    slug: PropTypes.string,
    totalItems: PropTypes.number,
  }).isRequired,
}

export default ItemDetails
