import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const slideTop = keyframes`
  to {
    transform: translateY(0);
  }
`

const ItemDetailsLayout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  width: 100%;
  height: 100%;
  padding-left: 2vw;
  padding-right: 4vw;
  overflow-y: auto;
  z-index: 3;

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3em 0 3em 2vw;
    width: 100%;

    @media (max-width: ${breakpoints.screenLG}) {
      padding: 2.2em 0 2.2em 2vw;
    }
  }

  .control {
    font-size: 0.95vw;
    font-weight: 600;
    line-height: 1.8;
    letter-spacing: 0.08em;
    color: ${({ theme }) => theme.textPrimary};
    text-transform: uppercase;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 2vw;
    }
  }

  .controls-group {
    display: flex;
    gap: 4vw;

    @media (max-width: ${breakpoints.screenLG}) {
      gap: 8vw;
    }
  }

  .disabled {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    opacity: 0.4;
  }

  .images {
    display: flex;
    flex-direction: column;
    gap: 4vh;
    width: 50%;
    height: 100%;
    padding-bottom: 4%;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 80%;
    }
  }

  .description {
    padding-left: 4vw;
    padding-top: 4%;
    width: 50%;
    text-align: center;

    @media (max-width: ${breakpoints.screenLG}) {
      padding-left: 0;
      width: 80%;
    }
  }

  .title {
    margin-bottom: 6%;
    opacity: 0;
    transform: translateY(8vh);
    animation: ${fadeIn} 0.6s ease-out 0.6s forwards,
      ${slideTop} 1.6s cubic-bezier(0.02, 0.98, 0.36, 1) 0.6s forwards;
  }
`

const Description = styled.div`
  font-size: 0.95vw;
  line-height: 1.8;
  font-weight: 400;
  letter-spacing: 0.08em;
  color: ${({ theme }) => theme.textSecondary};
  opacity: 0;
  transform: translateY(10vh);
  animation: ${fadeIn} 0.6s ease-out 0.65s forwards,
    ${slideTop} 1.8s cubic-bezier(0.02, 0.98, 0.36, 1) 0.65s forwards;

  @media (max-width: ${breakpoints.screenLG}) {
    font-size: 1.8vw;
  }

  p {
    margin-bottom: 2%;

    @media (max-width: ${breakpoints.screenLG}) {
      margin-bottom: 4%;
    }
  }
`

const SocialShareLayout = styled.div`
  margin: 8% 0;
  opacity: 0;
  transform: translateY(10vh);
  animation: ${fadeIn} 0.6s ease-out 0.7s forwards,
    ${slideTop} 1.8s cubic-bezier(0.02, 0.98, 0.36, 1) 0.7s forwards;

  .group-title {
    color: ${({ theme }) => theme.textPrimary};
    letter-spacing: 0.08em;
    text-transform: uppercase;
    margin-bottom: 3%;
  }

  .copy-link {
    font-size: 0.95vw;
    font-family: 'Source Sans Pro', sans-serif;
    color: ${({ theme }) => theme.textPrimary};
    background-color: ${({ theme }) => theme.backgroundPrimary};
    border: 0.06em solid ${({ theme }) => theme.textPrimary};
    max-width: 20vw;
    padding: 1em 1em;
    margin: 0 auto 8%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.5s ease;

    @media (max-width: ${breakpoints.screenLG}) {
      font-size: 1.8vw;
      max-width: 60vw;
    }

    svg {
      margin-right: 0.5vw;

      @media (max-width: ${breakpoints.screenLG}) {
        margin-right: 2vw;
        width: 30px;
        height: auto;
      }

      path {
        transition: all 0.4s ease;
      }
    }

    :hover {
      color: ${({ theme }) => theme.textLight};
      background-color: ${({ theme }) => theme.btnHoverBackground};
      border: 0.06em solid ${({ theme }) => theme.btnHoverBackground};

      svg path {
        fill: ${({ theme }) => theme.textLight};
      }
    }
  }

  .links {
    display: flex;
    justify-content: center;
    gap: 0.75vw;

    @media (max-width: ${breakpoints.screenLG}) {
      gap: 2vw;
    }
  }

  .react-share__ShareButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.06em solid ${({ theme }) => theme.textPrimary} !important;
    border-radius: 100%;
    width: 2.8vw;
    height: 2.8vw;
    transition: opacity 0.3s linear, color 0.5s ease, background-color 0.5s ease,
      border 0.5s ease;

    @media (max-width: ${breakpoints.screenLG}) {
      width: 5vw;
      height: 5vw;
    }

    :hover {
      color: ${({ theme }) => theme.textLight};
      background-color: ${({ theme }) => theme.btnHoverBackground} !important;
      border: 0.06em solid ${({ theme }) => theme.btnHoverBackground} !important;

      svg path {
        fill: ${({ theme }) => theme.textLight};
      }
    }
  }

  circle {
    fill: transparent;
  }

  path {
    fill: ${({ theme }) => theme.textPrimary};
  }
`

const Image = styled.div`
  will-change: transform;
  opacity: 0;
  transform: translateY(8vh);
  animation: ${fadeIn} 0.6s ease-out 0.7s forwards,
    ${slideTop} 1.8s cubic-bezier(0.02, 0.98, 0.36, 1) 0.7s forwards;
`

export { ItemDetailsLayout, Description, SocialShareLayout, Image }
